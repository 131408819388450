import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBsxN0BRzW1OJ2COoIKjjQ6ffuewhcfKz8",
  authDomain: "connecting-the-dots-web.firebaseapp.com",
  databaseURL: "https://connecting-the-dots-web.firebaseio.com",
  projectId: "connecting-the-dots-web",
  storageBucket: "connecting-the-dots-web.appspot.com",
  messagingSenderId: "850180604233",
  appId: "1:850180604233:web:27b37d0b42dec590f59a98",
  measurementId: "G-8D21SQETT5"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
